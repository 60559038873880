.App {
  text-align: center;
}
.toast-custom {
  background-color: #1e293b; /* slate-900 */
  color: white; /* Ensure text is readable */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px; 
  height: 100px; 
  margin-top: -50px; 
  margin-left: -50px; 
  border-radius: 50%;
  border: 9px solid #202938;
  border-top-color: #6D2ADA;
  animation: spin 2s linear infinite;
}
.fc-timegrid-slots .fc-slats .fc-slats-row:last-child {
  display: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rbc-calendar {
  @apply font-sans;
}

.rbc-toolbar {
  @apply mb-4;
}

.rbc-toolbar button {
  @apply bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-200 border border-gray-300 dark:border-gray-600 rounded py-1 text-sm font-medium;
}

.rbc-toolbar button.rbc-active {
  background-color: #7e22cd !important; /* Change this to your desired color */
  color: white !important;
}

.rbc-month-view, .rbc-time-view {
  @apply border border-gray-300 dark:border-gray-600 rounded-lg overflow-hidden;
}
.rbc-active {
  background-color: #7e22cd !important; /* Change this to your desired color */
  color: white !important;
  border: none; /* Adjust text color if needed */
}
.rbc-month-row {
  @apply border-b border-gray-300 dark:border-gray-600;
}

.rbc-header {
  @apply dark:text-gray-200 text-black font-semibold py-2;
}
.rbc-day-bg {
  @apply bg-white dark:bg-gray-800;
}

.rbc-today {
  @apply bg-blue-50 dark:bg-blue-900/20 text-gray-800;
}

.rbc-event {
  @apply bg-green-500 text-white rounded text-sm w-full;
}
/* Hide the "week" button on small screens */
.rbc-events-container{
  @apply w-full;
}
.rbc-off-range-bg {
  @apply bg-gray-100 dark:bg-gray-700/50;
}

.rbc-time-slot {
  @apply text-gray-500 dark:text-gray-400;
}
.rbc-time-slot:hover {
  background-color: #374151 !important;
}
.rbc-current-time-indicator {
  @apply bg-red-500;
}
@media (max-width: 768px) {
  .rbc-btn-group .hidden {
    display: none;
  }
}
.rbc-btn-group button.active,
.rbc-btn-group button:active,
.rbc-btn-group button:focus {
  background-color: #6b46c1;
  color: white !important;
  outline: none !important;
}
.rbc-btn-group button.active{
  background-color: #6b46c1!important;
  color: white;
}
.rbc-allday-cell {
  @apply hidden;
}
.rbc-day-slot .rbc-time-slot {
  border: none !important;
  cursor: pointer;
}

.rbc-header {
  border-bottom: none !important;
}

.rbc-time-content {
  display: flex;
  flex: 1 0;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid #ddd !important;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(221, 221, 221);
  overflow-y: auto;
  position: relative;
}

/* Time grid styling */
.rbc-timeslot-group {
  @apply border-b border-gray-200 dark:border-gray-700;
  min-height: 40px;
}
.rbc-timeslot-group:hover {
  border-bottom: 1px solid #e5e7eb;  /* light mode border */
  background-color: #0549a1!important;         /* light mode background */
  min-height: 40px;
}
.rbc-time-slot {
  @apply border-t border-gray-200 dark:border-gray-700;
  height: 30px;
}

.rbc-time-gutter {
  @apply bg-gray-50 dark:bg-gray-800;
}

.rbc-time-gutter .rbc-timeslot-group {
  @apply border-r border-gray-200 dark:border-gray-700;
}

.rbc-time-content {
  @apply border-t border-gray-200 dark:border-gray-700;
}

.rbc-time-header-content {
  @apply  border-gray-200 dark:border-gray-700;
}

.rbc-time-view {
  @apply bg-white dark:bg-gray-800;
}

/* Remove default borders that cause inconsistency */
.rbc-day-slot .rbc-time-slot {
  border: none;
}

/* Add grid lines for 30-minute intervals */
.rbc-time-slot:nth-child(2n) {
  @apply border-t border-gray-100 dark:border-gray-700/50;
}

/* Style the time gutter labels *

/* Ensure consistent height for time slots */
.rbc-time-content .rbc-day-slot {
  @apply border-r border-gray-200 dark:border-gray-700;
}

.rbc-events-container {
  @apply border-r border-gray-200 dark:border-gray-700;
}

/* Time header consistency */
.rbc-time-header.rbc-overflowing {
  @apply border-b border-gray-200 dark:border-gray-700;
}

/* Current time indicator */
.rbc-current-time-indicator {
  @apply bg-red-500 h-px;
}

.react-datepicker {
  color: #fff !important; /* White text */
  padding: 10px !important;
  border-radius: 8px !important;
  width: 100% !important;
  background-color: #374151 !important; /* slate-900 */
  border: 0px solid #4b5563 !important; /* Gray border */
  text-transform: capitalize !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #4b5563 !important; /* Gray border */
  width: 100% !important;
  background-color: #ffffff00 !important; /* slate-900 */
}
.react-datepicker__month-container
{
  width: 100% !important;
  color: #ffffff !important; /* White text */

}
.react-datepicker__current-month {
  color: #ffffff !important; /* White text */
}
.react-datepicker__day-names {
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
  -webkit-text-fill-color: #ffffff;
}
.react-datepicker__navigation-icon{
  top: 10px !important;
  color:#00af5a !important;
}

.react-datepicker__day:hover {
  background-color: #7d3af2 !important; /* Hover day background */
  color: #ffffff !important; /* Hover day text */
}
 .react-datepicker__day--selected,
 .react-datepicker__day--keyboard-selected {
  background-color: #7d3af2 !important; /* Selected day background */
}

.react-datepicker__day--disabled {
  background-color: #fa10106d !important; 
  border-radius: 20%;/* Disabled day color */
  cursor: not-allowed !important;
  pointer-events: none;
}


